<!--
 * @Description: 首页 banner
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-21 08:35:04
 * @LastEditors: 康蔚明
 * @LastEditTime: 2020-09-29 14:05:24
-->
<template>
  <div class="banner" v-if="bannerList && bannerList.length > 0">
    <div class="banner-wrapper">
      <div id="bannerSwiper"
           class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="(item,index) in bannerList"
               :key="index"
               class="swiper-slide">
            <div class="banner-item"
                 :style="{ backgroundImage:`url(${item.imgKey})`}">
              {{ item.id }}
            </div>
          </div>
        </div>
        <div class="banner-pagination"></div>
      </div>
      <div class="swiper-button-container">
        <div class="swiper-button-wrapper">
          <div class="swiper-button button-prev"
               @click="changeSwiper(true)">
          </div>
          <div class="swiper-button button-next"
               @click="changeSwiper(false)">
          </div>
        </div>
      </div>
      <div class="major-container">
        <div class="major-wrapper">
          <div class="major-allList">
            <p class="major-listAll-icon"></p>
            <h2>全部课程分类</h2>
          </div>
          <div class="major-list">
            <ul>
              <li v-for="(item, index) in CategoryTreeList" :key="index">
                <p class="major-list-icon"></p>
                <p class="major-list-tit">{{ item.label }}</p>
                <p class="major-list-next"></p>
                <div class="li-show">
                  <div class="tit" v-for="(itemOne, oneIndex) in item.children" :key="oneIndex">{{ itemOne.label }}
                    <div class="item">
                      <div class="item-boder" v-for="(itemTwo, twoIndex) in itemOne.children" :key="twoIndex"
                           @click="routerCourse(index, oneIndex, twoIndex, item.id, itemOne.id, itemTwo.id)">
                        {{ itemTwo.label }}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {commonApi} from '@/api/common';
import {categoryApi} from '@/api/category';
import {PIC_LOCATION_ENUM, PIC_TYPE_ENUM, PIC_GOTO_TYPE_ENUM} from '@/constant/common';

export default {
  name: 'IndexBanner',
  components: {},
  filters: {},
  props: {},
  data() {
    return {
      bannerList: [],
      CategoryTreeList: []
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters['user/userInfo'];
    }
  },
  watch: {},
  created() {
  },
  mounted() {
    this.queryBanner();
    this.getCategoryTree();
  },
  methods: {

    // 查询banner
    async queryBanner() {
      const data = {location: PIC_LOCATION_ENUM.PC_HOME.value};
      const result = await commonApi.queryBanner(data);
      this.bannerList = result.data;
      console.log(this.bannerList)
      this.$nextTick(() => {
        this.mySwiper = new Swiper('#bannerSwiper', {
          loop: true,
          autoplay: 5000,
          autoplayDisableOnInteraction: false,
          pagination: '.banner-pagination',
          paginationClickable: true,
          onClick: (swiper) => {
            const itemData = this.bannerList[swiper.realIndex];
            this.openBannerLink(itemData);
          }
        });
      });
    },
    // 查询科目
    async getCategoryTree() {
      const result = await categoryApi.getCategoryTree();
      console.log(result)
      this.CategoryTreeList = result.data
    },
    // 跳转课程 携带三级菜单的各个index
    routerCourse(index, oneIndex, twoIndex, itemId, itemOneId, itemTwoId) {
      this.$router.push({
        path: `/course`,
        query: {index, oneIndex, twoIndex, itemId, itemOneId, itemTwoId}
      });
    },
    // 左右切换轮播图
    changeSwiper(toPrev) {
      if (toPrev) {
        this.mySwiper.slidePrev();
        return;
      }
      this.mySwiper.slideNext();
    },

    // 点击轮播图
    openBannerLink(item) {
      // item.type为轮播图类型
      // type为仅展示
      // type为url
      if (item.gotoType === PIC_GOTO_TYPE_ENUM.URL.value) {
        const tempWindow = window.open('_blank');
        tempWindow.location = item.gotoData;
        return;
      }
      // type为课程
      if (item.gotoType === PIC_GOTO_TYPE_ENUM.COURSE.value) {
        this.$router.push({
          path: `/course/detail?courseId=${item.gotoData}`
        });
      }
    }
  }
};
</script>
<style lang='less' scoped>
.banner-wrapper {
  height: 384px;
  position: relative;
  margin-top: 40px;

  .swiper-container {
    height: 384px;

    .swiper-slide {
      .banner-item {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        //background-size: 100% 100%;
        background-position: center top;
        cursor: pointer;
        font-size: 46px;
        color: #fff;
        text-align: center;
      }
    }

    .banner-pagination {
      z-index: 10;
      position: absolute;
      left: 0;
      bottom: 18px;
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      /deep/ .swiper-pagination-bullet {
        display: block;
        width: 14px;
        height: 6px;
        border-radius: 3px;
        margin: 0 12px;
        background-color: #fff;
        transition: 0.2s;

        &.swiper-pagination-bullet-active {
          width: 30px;
        }
      }
    }
  }

  .swiper-button-container {
    position: absolute;
    width: 1200px;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;

    .swiper-button-wrapper {
      position: relative;

      .swiper-button {
        width: 34px;
        height: 60px;
        position: absolute;
        top: 162px;
        background: url("~@/assets/image/index/banner-swiper-button.png") center no-repeat;
        z-index: 3;
        cursor: pointer;

        &.button-prev {
          left: -74px;
        }

        &.button-next {
          right: -74px;
          transform: rotate(180deg);
        }
      }
    }

  }

  .major-container {
    position: absolute;
    width: 1200px;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;

    .major-wrapper {
      position: relative;

      .major-allList {
        position: absolute;
        top: -40px;
        // left: 350px;
        width: 200px;
        height: 40px;
        line-height: 40px;
        z-index: 3;
        background-color: #1462ED;
        border-radius: 5px 5px 0 0;
        display: flex;
        align-items: center;
      }

      .major-allList {
        position: absolute;
        // top: -40px;
        // left: 350px;
        width: 200px;
        height: 40px;
        line-height: 40px;
        z-index: 3;
        background-color: #1462ED;
        border-radius: 5px 5px 0 0;
        display: flex;
        align-items: center;

        .major-listAll-icon {
          margin: 0 10px;
          width: 10px;
          height: 10px;
          transform: rotate(180deg);
          background: url("~@/assets/image/index/Menu.png") center no-repeat;
        }

        h2 {
          color: #fff;
          text-align: left;
        }
      }

      .major-list {
        position: absolute;
        top: 0;
        // left: 350px;
        width: 200px;
        height: 424px;
        z-index: 3;
        color: #fff;
        text-align: left;
        background-color: rgba(42, 42, 42);
        border-radius: 0 0 5px 5px;

        ul {
          width: 200px;
          height: 424px;
          overflow: hidden;
          li {
            height: 30px;
            line-height: 30px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .major-list-tit {
              width: 130px;
              overflow: hidden;
            }

            .major-list-next {
              width: 10px;
              height: 10px;
              transform: rotate(180deg);
              background: url("~@/assets/image/index/major-list-button.png") center no-repeat;
            }

            .major-list-icon {
              width: 20px;
              height: 20px;
              background: url("~@/assets/image/index/book.png") center no-repeat;
            }

            .li-show {
              border-radius: 0 10px 10px 0;
              width: 0;
              height: 0;
              background-color: #fff;
              box-shadow: 0px 2px 6px 0px#1462ED / 16%;
              overflow: hidden;
              overflow-y: auto;
              position: absolute;
              left: 100%;
              top: 0;

              .tit {
                font-size: 16px;
                text-align: left;
                color: black;
                margin-left: 20px;

                .item {
                  display: flex;
                  flex-wrap: wrap;

                  .item-boder {
                    margin-right: 20px;
                    display: block;
                    padding: 6px 15px;
                    color: #666;
                    margin-bottom: 12px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    cursor: pointer;
                  }

                  .item-boder:hover {
                    border: 1px solid #1462ED;
                    color: #1462ED;
                  }
                }
              }
            }
          }

          li:hover .li-show {
            height: 424px;
            width: 430px;
            padding: 22px 30px;
          }

          li:hover {
            // background: #1462ED;
            background: rgba(20, 98, 237, 0.5)
          }
        }
      }
    }
  }
}
</style>
