import { Spin } from 'view-design';
import { examApi } from "../api/exam";

export const state = () => ({
  queryForm: {
    // 所属类目|可选
    categoryId: null,
    // 题库名称
    questionName: null,
    // 是否查询总数
    isSearchCount: true,
    // 关键词
    searchWord: '',
    // 价格排序
    sortByPrice: null,
    // 销量排序
    sortBySales: null,
    // 排序字段集合
    sortItemList: null,
    pageNum: 1,
    pageSize: 20
  },
  // 搜索结果
  questionList: [],
  // 搜索结果数量
  questionCount: 0,
  // 分页信息
  pageInfo: {
    pageNum: 1,
    pageSize: 20,
    pageTotal: 0
  }
});
export const getters = {};
export const mutations = {

  /**
     * 清空查询参数
     * @param state
     * @constructor
     */
  CLEAR_QUERY_FORM (state) {
    state.queryForm = {
      // 所属类目|可选
      categoryId: null,
      // 题库名称
      questionName: null,
      // 是否查询总数
      isSearchCount: true,
      // 关键词
      searchWord: null,
      // 价格排序
      sortByPrice: null,
      // 销量排序
      sortBySales: null,
      // 排序字段集合
      sortItemList: null,
      pageNum: 1,
      pageSize: 20
    };
  },

  // 设置专业信息
  SET_CATEGORY (state, categoryId) {
    if (categoryId) {
      state.queryForm.categoryId = categoryId;
    } else {
      state.queryForm.categoryId = null;
    }
    state.queryForm.pageNum = 1;
  },
  // ------------------------ 设置 全部------------------------
  // 全部
  SET_QUERY_ALL (state) {
    state.queryForm.queryAuditionFlag = null;
    state.queryForm.pageNum = 1;
  },
  // ------------------------ 设置 全部------------------------

  // 设置销量排序 asc desc
  SET_ENROLLMENT_SORT (state, data) {
    state.queryForm.sortByPrice = null;
    if (data === null) {
      state.queryForm.sortBySales = null;
    } else {
      state.queryForm.sortBySales = data;
    }
    state.queryForm.pageNum = 1;
  },

  // 设置价格排序
  SET_PRICE_SORT (state, data) {
    state.queryForm.sortBySales = null;
    if (data === null) {
      state.queryForm.sortByPrice = null;
    } else {
      state.queryForm.sortByPrice = data;
    }
    state.queryForm.pageNum = 1;
  },

  // 搜索结果
  SET_QUESTION_LIST (state, data) {
    state.questionList = data.list;
    state.questionCount = data.total;
    state.pageInfo.pageNum = data.pageNum;
    state.pageInfo.pageSize = data.pageSize;
    state.pageInfo.pageTotal = data.total;
  },

  // 设置分页参数
  SET_QUERY_PAGE_PARAM (state, data) {
    state.queryForm.pageNum = data.pageNum;
    state.queryForm.pageSize = data.pageSize;
  },
  // 设置搜索关键字
  SET_SEARCH_WORD (state, data) {
    state.queryForm.searchWord = data;
    state.queryForm.pageNum = 1;
  },
};

export const actions = {

  // 查询
  async QUERY_QUESTION ({ commit, state }) {
    Spin.show();
    try {
      const res = await examApi.postExamRoomList(state.queryForm);
      console.log('121', res);
      commit('SET_QUESTION_LIST', res.data);
    } catch (e) {
      $nuxt.$smartSentry.captureException(e);
    }
    Spin.hide();
  }

};
