/*
 * @Description: seo
 * @Author: hanyu
 * @Date: 2021-03-02 15:18:01
 * @LastEditTime: 2021-03-02 15:18:43
 * @LastEditors: hanyu
 */
// seo 类型
export const WEB_PAGE_TDK_PAGE_ENUM = {
    HOME: {
        value: 1,
        desc: '首页'
    },
    COURSE: {
        value: 2,
        desc: '课程'
    },
    QUESTION: {
        value: 3,
        desc: '题库'
    },
    TEACHER: {
        value: 4,
        desc: '师资'
    },
    ABOUT_US: {
        value: 5,
        desc: '关于我们'
    },
    EXAM: {
        value: 6,
        desc: '机考模拟'
    }
}

export default {
    WEB_PAGE_TDK_PAGE_ENUM
};
