/*
 * @Description: 题库相关api
 * @Author: hzf
 * @Date: 2023-01-29 14:06:45
 */
import { postAxios, getAxios } from '@/lib/http';
export const examApi = {
  // 查询机考商品详情
  getQuestionDetail (questionGoodsId) {
    return getAxios(`/app/question/goods/detail/${questionGoodsId}`);
  },
  // 分页查询机考商品列表
  postExamRoomList (data) {
    return postAxios('/app/question/examRoom/goods/query', data)
  },
  // 根据机考商品id查询专业和科目 by listen
  getExamRoomGoodsDetailByMajor (goodsId) {
    return getAxios(`/app/question/examRoomGoods/major/list/${goodsId}`);
  },
  // 查询机考模拟商品剩余考试次数
  getExamNumCount (roomMajorId, majorSubjectId) {
    return getAxios(`/app/question/examRoomGoods/remainExamNum/${roomMajorId}/${majorSubjectId}`);
  },
  // 机考科目-查询已拥有机考模拟商品 by listen
  getExamRoomGoodsList () {
    return getAxios('/app/question/query/examRoomGoods');
  }
};
