/*
 * @Description: 集成iview
 * @Author: hanyu
 * @Date: 2020-05-09 14:29:14
 * @LastEditTime: 2020-12-14 11:18:30
 * @LastEditors: hanyu
 */
import Vue from 'vue';

import {
  Icon,
  Spin,
  Modal,
  Message,
  Button,
  Tag,
  Page,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  Divider,
  Avatar,
  CheckboxGroup,
  Checkbox,
  Row,
  Table,
  Form,
  InputNumber,
  Badge,
  Cascader,
  Switch,
  FormItem,
  Select,
  Option
} from 'view-design';

Vue.component('Icon', Icon);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('Tag', Tag);
Vue.component('Page', Page);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Input', Input);
Vue.component('Divider', Divider);
Vue.component('Avatar', Avatar);
Vue.component('CheckboxGroup', CheckboxGroup);
Vue.component('Checkbox', Checkbox);
Vue.component('Row', Row);
Vue.component('Table', Table);
Vue.component('Spin', Spin);
Vue.component('InputNumber', InputNumber);
Vue.component('Form', Form);
Vue.component('FormItem', FormItem);
Vue.component('Badge', Badge);
Vue.component('Cascader', Cascader);
Vue.component('i-switch', Switch);
Vue.component('Select', Select);
Vue.component('Option', Option);

Vue.prototype.$Spin = Spin;
Vue.prototype.$Modal = Modal;
Vue.prototype.$Message = Message;
