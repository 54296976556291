<!--
 * @Description: 首页 全部课程 等导航栏
 * @Author: hanyu
 * @Date: 2020-09-20 16:21:21
 * @LastEditTime: 2021-01-13 11:56:13
 * @LastEditors: hanyu
-->
<template>
  <div class="main-menu-box">
    <a v-for="item in routeData" :key="item.id" class="item-menu" :class="{ active: checkActive(item) }"
       @click="onTab(item)">
      {{ item.name }}
    </a>
  </div>
</template>

<script>
import {examApi} from '@/api/exam';

export default {
  data() {
    return {
      routeData: [
        {
          name: '首页',
          routeName: 'home',
          path: '/',
          id: 0
        },
        {
          name: '全部课程',
          routeName: 'course',
          path: '/course',
          id: 1
        },
        {
          name: '题库',
          // path: 'center/my-question',
          // routeName: 'my-question',
          // pcRoute: true, // 是否走 person-center 路由
          path: '/question-bank',
          routeName: 'question-bank',
          id: 2
        },
        {
          name: '名师团队',
          path: '/teacher',
          routeName: 'teacher',
          id: 3
        },
        {
          name: '关于我们',
          path: '/about',
          routeName: 'about',
          id: 6
        }
      ]

    };
  },
  computed: {
    checkActive() {
      return function (item) {
        let routeIncludes = this.$route.name.includes(item.routeName);
        if (item.routeName === 'in-development') {
          const id = Number(this.$route.query.id || -1);
          routeIncludes = id === item.id;
        }
        return routeIncludes;
      };
    }
  },
  mounted() {
    this.getExamGood()
  },
  methods: {
    // 判断是否有机考模拟商品
    async getExamGood() {
      this.$Spin.show();
      try {
        const queryForm = {
          // 所属类目|可选
          categoryId: null,
          // 题库名称
          questionName: null,
          // 是否查询总数
          isSearchCount: true,
          // 关键词
          searchWord: '',
          // 价格排序
          sortByPrice: null,
          // 销量排序
          sortBySales: null,
          // 排序字段集合
          sortItemList: null,
          pageNum: 1,
          pageSize: 20
        };
        const res = await examApi.postExamRoomList(queryForm);
        console.log('pc121', res)
        if (!this.lodash.isEmpty(res.data.list)) {
          const newItem = {
            name: '机考模拟',
            path: '/exam',
            id: 7
          };
          this.routeData.splice(3, 0, newItem);
        }
      } catch (e) {
        this.$smartSentry.captureException(e);
      }
      this.$Spin.hide();
    },
    // 跳转
    onTab(item) {
      if (item.pcRoute) {
        this.$pcRouter.push({path: item.path});
        return;
      }
      const routeData = this.$router.resolve({
        path: item.path,
        query: item.routeName === 'in-development' ? {
          id: item.id
        } : {}
      });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>
<style lang="less" scoped>
.main-menu-box {
  display: flex;
  align-items: center;
  font-size: 16px;

  .item-menu {
    // margin-left: 30px;
    padding: 0px 20px;
    color: #212121;
    position: relative;

    &:hover {
      color: @main-color;
    }

    &:not(:first-child)::after {
      content: '';
      height: 12px;
      width: 1px;
      background: #ededed;
      position: absolute;
      top: 3px;
      left: 0px;
    }
  }

  .active {
    // color: @main-color;
    font-weight: bolder;

    &::before {
      content: '';
      height: 4px;
      width: 24px;
      background: #1890ff;
      position: absolute;
      top: 26px;
      border-radius: 4px;
      left: 50%;
      margin-left: -12px;
    }
  }
}
</style>
